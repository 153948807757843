<template>
  <div class="row">
    <div class="flex md12">
      <va-modal
        v-model="showStatusBook"
        title="Status Guide"
        class="flex xs12"
        size="large"
        ok-text="Ok!"
        cancel-text="Close"
        @close="showStatusGuide = false"
      >
        <div class="flex row">
          <div class="flex md12">
            <div class="flex row">
              <div class="flex md12 mb-2">
                <h3>NIHSS+ Quotes Status Guide</h3>
              </div>
            </div>
            <div class="flex row">
              <div class="flex md12">
                <div v-for="status of statuses">
                  <span><b>{{ status.value }}: </b> <span class="normal">{{ status.description ? status.description : status.index }}</span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </va-modal>


      <va-card>
        <template slot="header">
          <div class="flex row ">
            <h3>
              NIHSS+ Quotes
              <va-button
                color="info"
                @click="showStatusGuide"
              >
                <span class="mr-2">Status Guide</span> <i class="fa fa-book" />
              </va-button>
            </h3>
          </div>
        </template>
        <div class="row align--center">
          <div class="flex xs12 md3">
            <va-input
              :value="term"
              :placeholder="'Search by name'"
              @input="search"
              removable
            >
              <va-icon
                name="fa fa-search"
                slot="prepend"
              />
            </va-input>
          </div>
          <div class="flex xs12 md2 ">
            <va-select
              v-model="statusFilter"
              @input="filterByStatus"
              label="Filter By"
              placeholder="Choose a status"
              key-by="index"
              text-by="value"
              :options="statuses"
            />
          </div>
          <div class="flex xs12 md2 offset--md3">
            <va-select
              v-model="perPage"
              :label="$t('tables.perPage')"
              :options="perPageOptions"
              no-clear
            />
          </div>
        </div>

        <va-data-table
          :fields="fields"
          :data="filteredData"
          :per-page="parseInt(perPage)"
          @row-clicked="showQuote"
          clickable
          hoverable
          :loading="loading"
        >
          <template
            slot="status"
            slot-scope="props"
          >
            <va-badge
              large
              :color="props.rowData.statusFormated.color"
              class="custom-pill"
            >
              {{ props.rowData.statusFormated.title }}
            </va-badge>
          </template>
          <template
            slot="actions"
            slot-scope="props"
          >
            <va-button
              small
              color="success"
            >
              View More
            </va-button>
          </template>
        </va-data-table>
      </va-card>
    </div>
  </div>
</template>

<script>

// import { SemipolarSpinner } from 'epic-spinners'
import firebase from 'firebase';
import {debounce} from 'lodash';
import axios from '@/scripts/interceptor.js';
import statuses from '../../data/quoteStatus';

// const db = firebase.firestore()
export default {
  name: 'Organizations',
  data: function() {
    return {
      email: '',
      statusFilter: '',
      filteredQuotes: [],
      statuses,
      loading: true,
      emailErrors: [],
      quotes: [],
      term: null,
      perPage: '10',
      showStatusBook: false,
      perPageOptions: ['10', '50', '100', '250'],
    };
  },
  computed: {
    fields() {
      return [
        {
          name: 'id',
          title: 'Quote #',
          sortField: 'displayName',
          width: '20%',
        },
        {
          name: 'main_name',
          title: 'Contact Name',
          sortField: 'displayName',
          width: '20%',
        },
        {
          name: 'phone',
          title: 'Contact Phone',
          sortField: 'phone',
          width: '10%',
        },
        {
          name: 'subscriptions_requested',
          title: 'Subs Requested',
          sortField: 'subscriptions_requested',
          width: '10%',
        },
        {
          name: 'createdAt',
          title: 'Created At',
          sortField: 'createdAt',
          width: '10%',
        },
        {
          name: '__slot:status',
          title: 'Status',
          sortField: 'displayName',
          width: '20%',
        },
        {
          name: '__slot:actions',
          title: 'Actions',
          sortField: '',
          width: '30%',
        },
      ];
    },
    filteredData() {
      if (!this.term || this.term.length < 1) {
        return this.quotes;
      }

      return this.quotes.filter((quote) => {
        let returnOrg = false;
        if (quote.name) {
          if (quote.name.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
            returnOrg = true;
          }
        }
        return returnOrg;
      });
    },
  },

  async created() {
    this.loading = true;
    try {
      const response = await axios.get('/api/quotes/list/organization');
      this.quotes = response?.data?.data || [];
      this.quotes.forEach((quote) => {
        quote.createdAt = new Date(quote.createdAt._seconds * 1000).toLocaleDateString();
        quote.statusFormated = this.find_status(quote.status);
      });
      this.filteredQuotes = this.quotes;
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    search: debounce(function(term) {
      /* eslint-disable */
        this.term = term;
      }, 100),
      filterByStatus(status) {
        if (!status) {
          this.quotes = [...this.filteredQuotes];
        } else {
          this.quotes = this.filteredQuotes.filter((quote) => {
            return quote.status === this.statusFilter?.index;
          });
        }
      },
      showQuote(row){
        this.$router.push("/quotes/details/" + row.id)
      },
      showStatusGuide(){
      //Show status guide
        this.showStatusBook = true;
      },
      find_status(status)
      {
          switch (status) {
              case "quote_produced":
                  return {
                    title: "Quote Produced",
                    color: "info"
                  }  // Produce Quote
              case "quote_accepted":
                  return {
                    title: "Quote Accepted By Customer",
                    color: "info"
                  } // Accept Quote by Customer
              case "quote_cancelled":
                  return {
                    title: "Quote Cancelled By Customer",
                    color: "red"
                  } // Quote cancelled by Customer
              case "quote_processing":
                  return {
                    title: "Waiting Review",
                    color: "orange"
                  } // Waiting for our team to accept the quote
              case "nihss_accepted": 
                  return {
                    title: "NIHSS+ Accepted",
                    color: "success"
                  } // Quote accepted by our team
              case "quote_pre_accepted":
                  return {
                    title: "Waiting User Registration",
                    color: "#063970"
                  } // Quote rejected by our team
              default:
                  return {
                    title: "Waiting User",
                    color: "#303030"
                  }
          }
      },
    }
  };
  </script>

 